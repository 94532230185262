.secondaryText-79 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: black !important;
  font-weight: 400;
  font-size: 12px;
  display: block;
  height: 18px;
  line-height: 16px;
}

.searchInputWrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  /* max-width: 300px; */
  width: 100%;
}

.searchInputWrapper input {
  border-radius: 50px;
  border: none;
  padding: 18px 5px;
  padding-right: 34px;
  background: #dee3ff;
  color: #5061ce;
  font: 19px/12px ligntNunito, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
.searchInputWrapper svg {
  fill: #5061ce;
  display: none;
}
.searchInputWrapper input:focus {
  outline: none;
}

.searchListWrapper {
  position: absolute;
  width: 100%;
  height: 210px;
  background-color: white;
  box-shadow: 0px 3px 6px #94949491;
  overflow: hidden;
  overflow-y: scroll;
  border-radius: 8px;
  /* padding: 4px 0px; */
  left: 0;
  top: 45px;
}
.searchListWrapper::before {
  display: absoltue;
}

.searchItem {
  min-height: 66px;
  max-height: 66px;
  height: 100%;
  width: 100%;
  display: flex;
  padding-left: 14px;
  /* border-bottom: 1px solid; */
  /* justify-content: center; */
  align-items: center;
}
.searchItem:hover {
  background-color: #dbfbff;
  cursor: pointer;
}
.searchItem img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}
.srchTitleWraper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 14px;
  flex-grow: 1;
  max-width: calc(100% - 60px);
}
.srchTitleWraper p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.srchTitleWraper p:first-child {
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 18px;
}
.srchTitleWraper p:nth-child(2) {
  color: #5061ce;
}

@media screen and (max-width: 600px) {
  .searchInputWrapper {
    display: flex;
    width: 100%;
  }
}
