.giveVoiceWrapper {
  flex-grow: 1;
  box-sizing: border-box;
  height: auto;
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  margin: 0px 2rem;
  max-width: 71vw;
  width: 100%;
  margin-bottom: 6rem;
}
i:before {
  content: "X";
  font-style: normal;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .giveVoiceWrapper {
    margin: 0rem;
    margin-bottom: 6rem;
  }
}
