.wrapper {
  z-index: 1;
  position: relative;
}

.top {
  margin-top: 30px !important;
}

.box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titlePara {
  font: 32px/70px nunitoExtrabold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  margin-bottom: 2px;
  color: #292730;
  opacity: 1;
  line-height: 150%;
}
.pillsTitle {
  margin: 8px 0px;
  font: 20px/25px nunitoExtrabold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #4132b4;
  opacity: 1;
}
.error {
  text-align: left;
  font: 12px/19px CabinRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #a51a1a;
  opacity: 1;
}
@media screen and (max-width: 600px) {
  .searchInputWrapper {
    display: flex;
    width: 100%;
  }
}
