.secondaryText-79, .title-59 {
  color: #5061CE;
}

.primaryText-78 {
  color: #292730;
  font: 16px/19px CabinRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  height: 20px;
}

.personaWrapper-76{
  width: 220px;
}