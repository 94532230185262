.searchItem {
  max-width: 400px;
  width: 100%;
  min-height: 85px;
  max-height: 85px;
  height: 100%;
  display: flex;
  padding-left: 14px;
  border: 1px solid #4132b4;
  border-radius: 12px;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
}
.searchItemVozDada {
  max-width: 100%;
  width: 100%;
  min-height: 85px;
  max-height: 85px;
  height: 100%;
  display: flex;
  border-radius: 12px;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: transparent;
}
.searchItem:hover {
  background-color: #dbfbff;
  cursor: pointer;
}
.searchItem img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 50%;
}
.srchTitleWraper {
  width: 70%;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1; */
  max-width: calc(100% - 60px);
}
.srchTitleWraper p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.srchTitleWraper p:first-child {
  font: normal normal normal 16px/15px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #5061ce;
  opacity: 1;
  margin-bottom: 1%;
}
.srchTitleWraper p:nth-child(2) {
  color: #292730;
  font: normal normal bold 24px/28px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  text-transform: lowercase !important;
}
.srchTitleWraper p:nth-child(2):first-line {
  /* line-height: 18px; */
  color: #292730;
  font: normal normal bold 24px/28px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  text-transform: capitalize !important;
}
.searchItem span {
  min-width: 34px;
  min-height: 34px;
  position: absolute;
  top: 4px;
  right: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.searchItem span:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
