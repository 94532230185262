.maindivSidebar {
  position: sticky;
  top: 0px;
  background-color: #4132b4;
  width: 220px;
  min-width: 200px;
  /* height: 752px; */
  height: 660px;
  border-radius: 0px 36px 36px 0px;
  opacity: 1;
  /* margin-right: -7px; */
  margin-top: 20px;
  /* background: #4132B4 0% 0% no-repeat padding-box; */
}

#photoMe {
  object-fit: cover;
  width: 100%;
  max-width: 60px;
  max-height: 60px;
  min-height: 60px;
}

.iconUser {
  background-color: transparent;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  height: 60px;
  text-align: center;
  border-radius: 50%;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
}

.col-md {
  padding-right: 10px;
}

@font-face {
  font-family: openSans;
  src: url(./../fonts/Open_Sans/OpenSans-Regular.ttf);
}

@font-face {
  font-family: nunitoExtrabold;
  src: url(./../fonts/Nunito/Nunito-ExtraBold.ttf);
}

@font-face {
  font-family: nunitoBold;
  src: url(./../fonts/Nunito/Nunito-Bold.ttf);
}

.ProfileDiv {
  width: 100%;
  height: 209px;
  display: block;
  padding: 5px 12px;
  position: relative;
  top: 3px;
}

.ProfilepicStyle {
  /* padding: 21px 68px; */
  position: relative;
  top: 10px;
  height: 100px;
  /* padding: 12% 33%; */
  display: flex;
  justify-content: center;
}

.userNamestyle {
  /* background: aliceblue;  */
  position: relative;
  top: 11px;
  /* left: 4px; */
  font: 18px/12px nunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #dee3ff;
  width: auto;
  opacity: 1;
  text-align: center;
  text-transform: lowercase !important;
}

.userNamestyle:first-line {
  /* background: aliceblue;  */
  position: relative;
  top: 11px;
  left: 4px;
  font: 18px/12px nunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #dee3ff;
  width: 196px;
  opacity: 1;
  text-align: center;
  text-transform: capitalize !important;
}

.userText1 {
  position: relative;
  left: 44px;
  top: -5px;
  color: #00aec7;
  font: 16px/70px nunitoExtrabold, Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
  opacity: 0.7;
  letter-spacing: 0px;
}

.userText2 {
  position: relative;
  top: -20px;
  left: 8px;
  color: #e6ecff;
  text-align: justify;
  opacity: 1;
  /* font-family: auto; */
  /* font-family: openSans; */
  font: 12px/17px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.divlist {
  /* background-color: rgb(115, 126, 115); */
  /* background-color: rgb(115, 126, 115); */
  width: 213px;
  /* height: 458px; */
  height: 261px;
  display: block;
  padding: 5px 12px;
  margin: 0px 7px;
}

.ulList {
  padding-left: 0px;
  list-style: none;
  /* background: aqua; */
  /* width: 175px; */
  min-height: 261px;
  margin: 0px;
}

.ulList a {
  text-decoration: none !important;
}

.ullist2 {
  list-style: none;
}

.ulList li {
  padding: 22px 0px 22px 0px;
}

.firstList {
  margin: 0px !important;
  width: 224px !important;
  border-radius: 50px 0px 0px 50px !important;
  height: 78px !important;
  cursor: pointer;
  padding-top: 17px !important;
}

.firstListBckRoud {
  margin: -8px 0px !important;
  width: 100%;
  border-radius: 50px 0px 0px 50px !important;
  height: 94px !important;
  cursor: pointer;
  background-image: url(./../home/assets/button-shape.svg);
  background-repeat: no-repeat !important;
  background-position: 100% -16px !important;
  background-size: 88% 126px !important;
}

.spanTextfirstListNested {
  color: #4132b4 !important;
  position: relative;
  top: -22px;
  left: 84px;
  opacity: 1;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-decoration: none !important;
}

/* .spanTextfirstListNested:hover {
  color: #4132b4 !important;
  position: relative;
  top: -23px;
  left: 92px;
  opacity: 1;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-decoration: none !important;
} */

.spanTextfirstList {
  color: #ffffff;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  opacity: 1;
  margin: 0px 76px;
  position: relative;
  top: -22px;
  left: 9px;
}

.firstlishIcon {
  padding-top: 11px;
  display: block;
  margin: 1px 51px;
  fill: #b7bfee;
  opacity: 1 !important;
}

.firstlistIcon {
  padding-top: 7px;
  display: block;
  margin: 1px 51px;
  fill: #b7bfee;
  opacity: 1 !important;
}

/* .firstlishIcon{

  display: block;
    margin: -12px 14px;
} */
.firstList:hover {
  cursor: pointer;
}

.firstList:hover .spanTextfirstList {
  color: #ffffff;
}

.secodList {
  padding-top: 0px !important;
  margin: -4px -60px;
  width: 234px;
  border-radius: 50px 0px 0px 50px;
  height: 50px;
}

.secondListBckRoud {
  margin: -4px -59px;
  width: 234px;
  border-radius: 50px 0px 0px 50px;
  height: 100px;
  cursor: pointer;
  background-image: url(./../home/assets/button-shape.svg);
  background-repeat: no-repeat !important;
  background-position: 32px -37px !important;
  background-size: 201px 172px !important;
}

.thirdListBckRoud {
  margin: -4px -26px;
  width: 201px;
  border-radius: 50px 0px 0px 50px;
  height: 100px;
  cursor: pointer;
  background-image: url(./../home/assets/button-shape.svg);
  background-repeat: no-repeat !important;
  background-position: 6px -25px !important;
  background-size: 194px 147px !important;
}

.thirdList {
  margin: 1px -27px 1px -9px;
  width: 202px;
  border-radius: 50px 0px 0px 50px;
  height: 53px;
  margin-left: -27px !important;
  padding-top: 11px !important;
}

.fourthListBckRoud {
  margin: -5px -26px;
  width: 201px;
  border-radius: 50px 0px 0px 50px;
  height: 100px;
  cursor: pointer;
  background-image: url(./../home/assets/button-shape.svg);
  background-repeat: no-repeat !important;
  background-position: 6px -25px !important;
  background-size: 194px 147px !important;
}

.fourthList {
  margin: 22px -27px 1px -9px;
  width: 202px;
  border-radius: 50px 0px 0px 50px;
  height: 84px;
  margin-left: -27px !important;
  padding-top: 0px !important;
}

.secondIcon {
  fill: #b7bfee;
  padding-top: 12px;
  /* margin-top: 4px; */
  /* height: 22px; */
  /* width: 34px; */
  display: block;
  margin: 1px 51px;
  /* position: relative; */
  /* top: 7px; */
  /* left: 8px; */
}

.thirdicon {
  padding-left: 7px;
  padding-top: 5px;
  height: 22px;
  margin: 6px 10px;
  position: absolute;
  color: #ffffff;
}

.fourthicon {
  padding-left: 7px;
  padding-top: 5px;
  height: 22px;
  margin: 6px 10px;
  position: absolute;
  /* fill: #ffffff;
  opacity: 0.7; */
}

/* .a_icon-chat {
  fill: rgba(65, 50, 180, 1) !important;
  fill: #ff808b !important;
} */
.spanTextsecondList {
  color: #ffffff;
  /* color: violet; */
  position: relative;
  top: -23px;
  /* left: 50px; */
  opacity: 1;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  margin: 0px 83px;
}

.spanTextsecondListNested {
  color: #4132b4;
  position: relative;
  top: -23px;
  left: 83px;
  opacity: 1;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.spanTexthirdListNested {
  color: #4132b4;
  position: relative;
  top: 15px;
  left: 49px;
  opacity: 1;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.spanTexthirdList {
  color: #ffffff;
  /* color: violet; */
  position: relative;
  top: 13px;
  left: 50px;
  opacity: 1;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.spanTexfourthListNested {
  color: #4132b4;
  position: relative;
  top: 15px;
  left: 49px;
  opacity: 1;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.spanTextfourthList {
  color: #ffffff;
  /* color: violet; */
  position: relative;
  top: 13px;
  left: 50px;
  opacity: 1;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.secodList:hover .spanTextsecondList {
  fill: #b7bfee !important;
  /* color: #F4F9FF */
}

.thirdlist:hover {
  /* background-color: #F4F9FF; */
  cursor: pointer;
}

.thirdList:hover .spanTexthirdList {
  color: #ffffff;
}

.fourthlist {
  /* background-color: #00AEC7; */
  margin: 0 -29px;
  width: 204px;
  border-radius: 50px 0px 0px 50px;
  height: 3px;
}

.fourthlist:hover {
  /* background-color: #F4F9FF; */
  cursor: pointer;
}

.fourthlist:hover .spanTextfirstList {
  color: #4132b4;
}

.fifthlist {
  /* background-color: #00AEC7; */
  margin: 0 -29px;
  width: 204px;
  border-radius: 50px 0px 0px 50px;
  height: 3px;
}

.fifthlist:hover {
  /* color: #F4F9FF; */
  cursor: pointer;
}

.fifthlist:hover .spanTextfirstList {
  color: #4132b4;
}

.goOut {
  height: 100px;
  width: 172px;
  display: block;
  margin: 14px 5px;
  /* background-color: cadetblue; */
  /* height: 134px;
    width: 172px;
    display: block;
    padding: 5px 13px;
    position: relative;
    top: 5px !important;
    left: -12px; */
}

.iconExit {
  /* background: aliceblue; */
  width: 24px;
  height: 24px;
  position: relative;
  top: 64px;
  left: 51px;
  display: block;
  /* fill: #4132b4; */
}

.goutTextStyl {
  position: relative;
  top: 42px;
  left: 85px;
  color: #ffffff;
  opacity: 1;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  background: transparent;
}

.iconExit:hover {
  cursor: pointer;
}

.goutTextStyl:hover {
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .maindivSidebar {
    /* position: sticky;
    top: 0px; */
    background-color: #4132b4 !important;
    width: 157px !important;
    height: 827px !important;
    border-radius: 0px 36px 36px 0px !important;
    opacity: 1 !important;
  }
}

.companioinimg {
  width: 31px;
}

.companionDiv {
  display: flex;
}

.recodvoiceicon {
  fill: rgba(136, 148, 222, 1) !important;
}

.Settingiconcom {
  fill: rgba(136, 148, 222, 1) !important;
}

.ullist2 > li {
  margin: 1px -52px;
}

.text2list {
  display: inline-block;
  padding: 0 12px;
  position: relative;
  top: 3px;
  color: #ffffff;
  opacity: 1;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.text3list {
  display: inline-block;
  padding: 0 6px;
  position: relative;
  top: 10px;
  color: #ffffff;
  opacity: 1;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.text4list {
  display: inline-block;
  padding: 0 11px;
  position: relative;
  top: 1px;
  color: #ffffff;
  opacity: 1;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.text5list {
  display: inline-block;
  padding: 0 7px;
  position: relative;
  top: 1px;
  color: #ffffff;
  opacity: 1;
  font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

@media screen and (min-width: 150px) and (max-width: 599px) {
  .ProfileDiv {
    display: none !important;
  }

  .maindivSidebar {
    top: auto;
    background-color: #4132b4 !important;
    width: 100% !important;
    height: 73px !important;
    border-radius: 25px 25px 0px 0px !important;
    opacity: 1;
    position: fixed;
    bottom: 0;
    z-index: 10;
  }

  .ulList {
    list-style-type: none !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    width: 100% !important;
  }

  .ulList li {
    /*padding: 22px 0px 22px 0px;*/
    float: left;
  }

  /* .firstList {
    background: #4132b4;

    width: 183px !important;
  } */
  .firstList {
    /* background-color: yellow; */
    /* background: #4132B4; */
    /* margin: -4px -23px !important; */
    width: 33% !important;
    border-radius: 50px 0px 0px 50px !important;
    height: 69px !important;
    cursor: pointer;
    /* position: fixed; */
  }

  .darvoz_titlePara__3jJ86 {
    font: 20px/27px nunitoExtrabold, Helvetica, Arial, Verdana, Tahoma,
      sans-serif !important;
  }

  .pedirvoz_titlePara__v3LH8 {
    font: 20px/27px nunitoExtrabold, Helvetica, Arial, Verdana, Tahoma,
      sans-serif !important;
  }

  /*.shared_pillsWrapper__2X5zZ {
    display: block !important;
  }*/

  .firstlistIcon {
    /* background-color: gold; */
    padding-top: 0px;
    padding-left: 0px;
    display: block;
    margin: 0px 68px;
    fill: #b7bfee;
    opacity: 1 !important;
  }

  .firstListBckRoud {
    margin: -4px -60px !important;
    width: 115px !important;
    border-radius: 50px 0px 0px 50px !important;
    height: 69px !important;
    cursor: pointer;
    background-image: none !important;
    background-repeat: none !important;
    background-position: none !important;
    background-size: none !important;
    display: none;
  }

  .spanTextfirstListNested {
    color: #ffffff !important;
    margin: 0 -53px;
    position: relative;
    top: -13px;
    opacity: 1;
    font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    text-decoration: none;
    /* position: relative;
    top: -23px;
    left: 83px;
    opacity: 0.7;
    font: 15px/12px openSans; */
  }

  /* .spanTextfirstListNested:hover {
    color: #ffffff !important;
    margin: 0 -53px;
    position: relative;
    top: -13px;
    opacity: 1;
    font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    text-decoration: none !important;
    position: relative;
    top: -23px;
    left: 83px;
    opacity: 0.7;
    font: 15px/12px openSans; 
  }*/

  .spanTextfirstList {
    color: #FFFFFF;
    font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    opacity: 1;
    margin: 0 38px;
    position: relative;
    top: 6px;
    display: block;
    /* color: #4132B4; */
  }

  /* .firstList:hover {
    background: #4132b4;
  } */

  .firstlishIcon {
    display: block;
    margin: 1px 51px;
    position: relative;
    top: -10px;
  }

  .spanTextsecondList {
    color: #ffffff;
    /* color: violet; */
    position: relative;
    top: 21px;
    left: 22px;
    opacity: 1;
    font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .secondIcon {
    width: 34px !important;
    display: block !important;
    margin: -16px 32px !important;
    position: relative !important;
    top: 6px !important;
  }

  .secodList {
    /* background-color: bisque; */
    /* background-color: bisque; */
    margin: -68px 41px !important;
    width: 202px !important;
    border-radius: 50px 0px 0px 50px !important;
    height: 84px !important;
  }

  .secondListBckRoud {
    /* margin: 1px -27px; */
    margin: -68px 41px !important;
    width: 202px;
    border-radius: 50px 0px 0px 50px;
    height: 84px;
    cursor: pointer;
    background-image: none !important;
    background-repeat: none !important;
    background-position: none !important;
    background-size: none !important;
  }

  .spanTextsecondListNested {
    color: #ffffff;
    position: relative;
    top: 10px;
    left: 26px;
    opacity: 1;
    font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  /* .secodList:hover {
    background: #4132b4;
  } */

  .secodList:hover .spanTextsecondList {
    color: #ffffff;
  }

  .goOut {
    display: none;
  }

  .logoStyl {
    display: none;
  }

  .dashboardnavbar {
    width: 100%;
    height: 75px;
    position: fixed;
    display: flex;

    justify-content: space-between;
  }

  .mainDivdashboard {
    margin: -986px 0px !important;
  }

  .searchboxrow {
    display: none;
  }

  .alertcomponentDiv {
    margin: -20px -5px !important;
  }

  /* ///////////2ndullist/ */
  .ullistf {
    display: flex;
    justify-content: center;
    position: relative;
    left: 110px;
    left: center;
    /* display: none !important; */
    top: -27px;
  }

  .text2list {
    display: inline-block;
    padding: 3px 9px;
    position: relative;
    top: 24px;
    color: #ffffff;
    opacity: 1;
    left: -46px;
    font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .companionDiv {
    display: flex;
    justify-content: center;
    position: relative;
    left: 211px;
    left: center;
    /* display: none !important; */
    top: -98px;
  }

  .text3list {
    display: inline-block;
    padding: 0 6px;
    position: relative;
    top: 29px;
    color: #ffffff;
    left: -56px;
    opacity: 1;
    font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .ullistfou {
    display: flex;
    justify-content: center;
    position: relative;
    left: 299px;
    left: center;
    /* display: none !important; */
    top: -171px;
  }

  .text4list {
    display: inline-block;
    padding: 0 6px;
    position: relative;
    top: 26px;
    color: #ffffff;
    left: -46px;
    opacity: 1;
    font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .ullistsix {
    display: flex;
    justify-content: center;
    position: relative;
    left: 367px;
    left: center;
    /* display: none !important; */
    top: -240px;
  }

  .text5list {
    display: inline-block;
    padding: 0 6px;
    position: relative;
    top: 26px;
    color: #ffffff;
    left: -42px;
    opacity: 1;
    font: 15px/12px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }
}

.navItem-active {
  text-decoration: none !important;
}

.navItem-active svg {
  fill: #4132b4 !important;
  text-decoration: none !important;
}

.navItem-active .a_chatFilledCopy {
  fill: #4132b4 !important;
}

.navItem-active .b_chatFilledCopy {
  fill: #ffffff !important;
}

