.dashboardSecondmainDiv::-webkit-scrollbar {
  display: none;
}

.dashboardThirdmainDiv::-webkit-scrollbar {
  display: none;
}

.divFlotanteVacio {
  left: 45px;
  position: absolute;
  margin: 16px 29px;
  width: 87%;
  height: 91%;
  z-index: 1;
}

.divFlotanteVacioAlineacion1 {
  display: flex;
  float: right;
}

.divFlotanteVacioAlineacion2 {
  height: 90px;
  width: 370px;
  display: flex;
  margin: 0% 34%;
}

.contenedorTusCompetencias {
  width: 90%;
  padding: 0;
}

.flexTusCompetencias {
  display: flex;
  width: 100%;
}

.competencia {
  margin: 0% auto;
  height: 53px;
  width: 62px;
}

.eCount {
  width: 61px;
  color: #4d4cac;
  opacity: 1;
  font: 32px/70px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: center;
}

.eText {
  letter-spacing: 0px;
  color: #4d4cac;
  opacity: 1;
  font: 14px/29px NunitoRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: center;
}

.bCount {
  width: 61px;
  color: #5d7ff1;
  opacity: 1;
  font: 32px/70px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: center;
}

.bText {
  letter-spacing: 0px;
  color: #5d7ff1;
  opacity: 1;
  font: 14px/29px NunitoRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: center;
}

.pCount {
  width: 61px;
  color: #ff808b;
  opacity: 1;
  font: 32px/70px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: center;
}

.pText {
  letter-spacing: 0px;
  color: #ff808b;
  opacity: 1;
  font: 14px/29px NunitoRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: center;
}

.mainDivdashboard {
  width: 80.0vw;
}

.searchboxmainWrapper {
  display: flex;
  flex-wrap: wrap;
  margin-left: -31px;
}

::placeholder {
  color: #5061ce;
  opacity: 0.7;
  /* Firefox */
}

.searchi {
  margin-left: -108px;
  fill: #5061ce;
}

.FindaPartnerInput {
  margin: 10px 0px;
  line-height: 30px;
  border-radius: 50px;
  border: none;
  padding: 7px 22px;
  min-width: 414px;
  width: 100%;
  background: #dee3ff;
}

.ms-BasePicker-text {
  border: none;
  outline: none;
  flex-wrap: nowrap;
}

.ms-BasePicker-text::after {
  outline: none;
  border-color: #5061ce;
  border-radius: 50px;
  margin: -6px -21px !important;
}

.ms-PickerPersona-container {
  background: #5061ce;
  margin: 6px 8px;
  color: #fff;
}

.ms-PickerPersona-container:hover {
  background: #5061ce;
}

.primaryText-52,
.primaryText-96,
.primaryText-99,
.primaryText-101,
.primaryText-104 {
  color: #fff;
}

.primaryText-52:hover,
.primaryText-96:hover,
.primaryText-99:hover,
.primaryText-101:hover,
.primaryText-104:hover {
  color: #fff;
  cursor: pointer;
}

.text-47::after {
  border: 1px solid rgb(0, 120, 212);
}

.ms-PickerItem-removeButton:active,
.ms-PickerItem-removeButton:hover {
  background: #5061ce;
  color: #fff;
}

.itemsWrapper-43 {
  width: 100%;
}

.graphDiv {
  width: 1024px !important;
  height: auto !important;
  margin: 7px 47px 22px !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 18px !important;
}

.FindaPartnerInput:focus {
  outline: none;
  border: 1px solid #5061ce !important;
  box-shadow: 0px 4px 9px 0px #5061ce30 !important;
}

.searchicon {
  margin: 0 -98px;
  width: 18px;
}

.searchboxrow {
  height: 68px !important;
}

.dropDownboxrow {
  height: 68px !important;
  padding-left: 0px;
  padding-top: 2px;
}

.dropDownboxrow .Dropdown-arrow {
  border-color: none !important;
  border-style: none !important;
  border-width: none !important;
  content: " ";
  display: block;
  height: 0;
  margin-top: -ceil(2.5);
  position: absolute;
  right: 10px;
  top: 14px;
  width: 0;
}

.Dropdown-control {
  position: relative !important;
  overflow: hidden !important;
  background-color: #f4f9ff !important;
  border: 1px solid #2d41b1 !important;
  border-radius: 8px !important;
  box-sizing: border-box !important;
  color: #5061ce !important;
  cursor: default !important;
  margin-top: -5px;
  outline: none !important;
  padding: 17px -1px 8px 10px !important;
  transition: all 200ms ease !important;
}

.Dropdown-placeholder {
  font-size: 20px !important;
}

.Dropdown-root {
  position: relative;
  width: 291px;
  margin: 0 83px;
  top: 10px;
}

.dropdownArrow {
  width: 28px !important;
  position: relative;
  display: inline-block;
  left: 340px;
  top: -23px;
  transform: rotate(90deg);
}

.clander {
  width: 103px;
  position: relative;
  left: 274px;
  /* top: 1xp; */
  top: -66px;
}

/* /////////////////2nd column alerts */
@font-face {
  font-family: exraBold;
  src: url(../fonts/Nunito/Nunito-ExtraBold.ttf);
}

@font-face {
  font-family: NunitoBlack;
  src: url(../fonts/Nunito/Nunito-Black.ttf);
}

@font-face {
  font-family: NunitoBold;
  src: url(../fonts/Nunito/Nunito-Bold.ttf);
}

@font-face {
  font-family: NunitoLight;
  src: url(../fonts/Nunito/Nunito-Light.ttf);
}

@font-face {
  font-family: NunitoRegular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}

.dashboardSecondmainDiv {
  margin: 0 15px 15px;
  position: relative;
  width: 80.0vw;
}

.calendar {
  display: flex;
  align-items: start;
  justify-content: flex-end;
}

.rs-picker-default .rs-picker-toggle {
  border: 1px solid #5061ce !important;
  border-radius: 8px !important;
}

.rs-picker-toggle .rs-picker-toggle-placeholder,
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: #5061ce !important;
  font: normal normal 300 20px/29px NunitoRegular, Helvetica, Arial, Verdana,
    Tahoma, sans-serif !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn-lg {
  width: 40% !important;
}

.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean {
  right: 45px !important;
}

.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
  top: 8px !important;
}

.calendar .rs-picker-daterange .rs-picker-toggle-caret::before {
  content: "\f133" !important;
  color: #5061ce;
}

.rs-picker-toggle.rs-btn-lg {
  font-size: 20px !important;
}

.calendar .rs-picker-default .rs-btn,
.rs-picker-input .rs-btn,
.rs-picker-default .rs-picker-toggle,
.rs-picker-input .rs-picker-toggle {
  background-color: transparent !important;
}

.welcomBack {
  font: 32px/70px exraBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: #292730;
  letter-spacing: 0px;
  opacity: 1;
  margin-bottom: 0px;
}

.alertcomponentDiv {
  margin: -18px 31px 13px 0px;
  bottom: -7px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 18px;
}

.emojiDiv {
  background-color: #dbe0ff;
  width: 366px;
  height: 161px;
  border-radius: 18px;
  opacity: 1 !important;
  margin: 42px -41px;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  top: 25px;
}

.emojiDivVoz {
  background-color: #dbe0ff;
  width: 365px;
  height: 161px;
  border-radius: 18px;
  opacity: 1 !important;
  margin: 0px -41px 14px;
  box-shadow: 0px 3px 6px #00000029;
  position: relative;
  /* top: 70px; */
  display: flex;
}

.flexFaceMonth {
  width: 100%;
  margin: 4% 3% 1vh;
}

.crossSymbol {
  position: relative;
  left: 326px;
  top: 9px;
}

.crossSymbol:hover {
  cursor: pointer;
}

.hidee {
  display: none;
}

.circleBlue {
  width: 99px;
}

.tweleveNumber {
  margin: -67px auto;
  width: 73px;
  color: #5061ce;
  font: 40px/70px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: center;
}

.april2020 {
  width: auto;
  display: flex;
  margin-bottom: 0px;
  height: 31px;
  justify-content: center;
  vertical-align: middle;
  color: #5061ce;
  font: 16px/70px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.blueCirclediv {
  width: 100%;
  text-align: center;
}

p.descripcion {
  margin: 0 !important;
  text-align: center;
  line-height: 22px;
}

.flexVoces {
  padding: 9% 3%;
  width: 100%;
}

.flexVocesMensaje {
  padding: 4% 12% 3% 1%;
  width: 50%;
}

.cerrar {
  width: 0px;
}

.noVoces {
  color: #292730;
  font: 18px/24px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  width: min(70%, 500px);
}

.pedirVozBtn {
  border: none;
  margin: 14px 0px;
  width: 100%;
  height: 35%;
  background: #4132b4 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font: 18px/24px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.pedirVozBtn:hover {
  color: #fff;
  background-color: #00aec7 !important;
  border-color: #00aec7;
}

.emojiStyl {
  width: 37px;
  margin: 3% auto 0px;
}

.yourDoingGreat {
  margin: auto !important;
  color: #292730;
  font: 24px/29px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  height: 87px;
  display: flex;
  align-items: center;
}

.voiceReceved {
  color: #5061ce;
  font: 9px/40px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  width: 92px;
  margin: -67px auto;
  margin-top: 70px;
  text-align: center;
}

.dashboardThirdmainDiv {
  width: 80.0vw;
  margin: 0px 0px 25px 14px;
  /* width: 1330px;
    margin: 0px 0; */
}

.dashboardFourthMainDiv {
  width: 78.0vw;
  max-width: 100vw;
  margin: 0px 0px 30px 28px;
}

.divChart {
  margin: 0px 14px 14px;
  width: 80.0vw;
}

.SkillsDiv {
  background-color: #e6ecff;
  width: 743px;
  height: 150px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 18px;
  display: flex;
}

.abilitiesDiv {
  background: #4d4cac 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  border-radius: 18px;
  width: 100%;
  min-height: 222px;
}

.yourOutstandingSkillsDiv {
  text-align: left;
  font: 24px/29px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 2.5% 4% 1% 5%;
}

.outstandingSkillsDiv {
  display: flex;
}

.yourOutstandingSkillsText {
  text-align: left;
  font: 24px/29px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #292730;
  opacity: 1;
  width: 35%;
  max-width: 330px;
  position: relative;
  line-height: 28px;
  padding: 6% 4% 6% 5%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.avgSkills {
  padding: 2% 0;
  width: 30%;
}

.avgSkillsText {
  text-align: left;
  font: 14px/15px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #292730;
  opacity: 1;
  padding-top: 0.2vh;
}

.avgSkillsNumber {
  text-align: right;
  font: 40px/70px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 2.4px;
  color: #5061ce;
  opacity: 1;
  margin-top: 1%;
  margin-right: 1vw;
  width: 50%;
}

.linearProgressBar {
  width: 90%;
}

.divRangeProgressBar {
  display: flex;
  color: #a2a2a2;
  opacity: 1;
}

.rangeProgressBar {
  display: contents;
  align-items: center;
  font-size: 11px;
  letter-spacing: 0px;
  color: #a2a2a2;
  opacity: 1;
  line-height: 30px;
}

.rangeProgressBar::after {
  content: "";
  flex: 1 1;
  margin-left: 4%;
  margin-right: 4%;
  position: relative;
  top: 9px;
  height: 1px;
  opacity: 1;
  background-color: #a2a2a2;
}

.wowSkillsText {
  text-align: left;
  font: 14px/15px NunitoRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #5061ce;
  opacity: 1;
  margin-top: 5%;
  width: 7vw;
}

.totalVoicesDiv {
  background-color: #f8f8f8;
  width: 100%;
  border-radius: 18px;
  height: 100%;
  box-shadow: 0px 3px 6px #00000029;
  right: 0px;
  position: relative;
}

.fila {
  width: 161px !important;
  margin: 0 auto;
}

.pedirVozBtnFila {
  border: none;
  width: 178px;
  height: 40px;
  background: #4132b4 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  font: 18px/24px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.pedirVozBtnFila:hover {
  color: #fff;
  background-color: #00aec7 !important;
  border-color: #00aec7;
}

.cerrarBtnDiv {
  border: none;
  width: 178px;
  height: 40px;
  background: #4132b4 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  float: right;
}

.yourStrongestSkillText {
  color: #292730;
  width: 40%;
  position: relative;
  font: 24px/29px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  line-height: 28px;
  padding: 6% 4% 6% 7%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexCompetencias {
  display: flex;
  width: 58%;
}

.contenedoresCompetencias {
  width: 32.3%;
  padding: 4% 0;
}

.flexCompetenciaVacio {
  display: flex;
  width: 60%;
}

.contenedorCompetenciaVacio {
  width: 90%;
  padding: 5% 0;
  display: flex;
}

.divimgCompetenciaVacio {
  margin: 5% 6% 5% 2%;
  height: 101px;
  width: 95px;
}

.derecho {
  text-align: right !important;
}

.txtCompetenciaVacio {
  height: 44px;
  font: 18px/24px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: #4d4cac;
  opacity: 1;
  margin: 7% 6%;
}

.txtCompetenciaBarVacio {
  width: 412px;
  height: 44px;
  font: 18px/24px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: #4d4cac;
  opacity: 1;
  margin: 4% 3%;
}

p.valorCompetencia {
  margin: 0 !important;
  text-align: center;
}

.courageText {
  height: 44px;
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  font: 14px/29px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: #292730;
  opacity: 1;
  vertical-align: bottom;
}

.ColaborationText {
  height: 44px;
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  font: 14px/29px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: #292730;
  opacity: 1;
  vertical-align: bottom;
}

.inovationText {
  height: 44px;
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  font: 14px/29px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: #292730;
  vertical-align: bottom;
  opacity: 1;
}

.circleBlueSecond {
  width: 62px;
  height: 62px;
}

.firstcircleBluesecond {
  margin: 5% 29%;
  height: 62px;
  width: 62px;
}

.blueCirclecount {
  /* background-color: fuchsia; */
  width: 47px;
  margin: -43px 7px;
  color: #5061ce;
  font: 25px/27px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.secondCircle {
  width: 62px;
  height: 62px;
  /* background-color: aqua; */
}

.SecondcircleDiv {
  margin: 5% 29%;
  height: 62px;
  width: 62px;
}

.secondCicleCount {
  /* background-color: fuchsia; */
  width: 47px;
  margin: -43px 7px;
  font: 25px/27px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: #5061ce;
}

.thirdcircle {
  width: 62px;
  height: 62px;
  /* background-color: gold; */
}

.thirdcircleDiv {
  /* background-color: aqua; */
  width: 62px;
  margin: 5% 29%;
  height: 62px;
}

.thirdcircleCount {
  width: 47px;
  margin: -43px 7px;
  font: 25px/27px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: #5061ce;
}

.totalVoicesReceived {
  width: max-content;
  margin: 0px auto;
  position: relative;
  top: 16px;
  color: #292730;
  font: 14px/15px exraBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.totalVoicesCount {
  /* background-color: lightskyblue; */
  width: 47px;
  margin: 32px 18px;
  font: 38px/41px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: -2.4px;
  color: #5061ce;
  text-align: center;
}

.grayFace {
  /* background-color: lightskyblue; */
  margin: 6% 7% 7% 16%;
  height: 61px;
}

.DivTextDesc {
  width: 102px !important;
  margin: 9% 6% 3% 1%;
  font: 14px/15px NunitoRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #5061ce;
  height: 61px;
  padding: 3% 4% 3% 1%;
}

.wowDivText {
  /* background-color: lime; */
  width: 87px;
  margin: -77px 88px;
  /* margin: 0px 88px; */
  font: 14px/15px NunitoRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #5061ce;
}

.giftImgDiv {
  /* background: aqua; */
  width: 46px;
  margin: 35px 184px;
}

.upDownLinesDiv {
  /* background: blueviolet; */
  width: 171px;
  margin: -27px 65px;
}

.updownLineimg {
  width: 160px;
  height: 44px;
}

.graph {
  height: 386px;
  margin: 24px 120px;
}

@media screen and (max-width: 833px) {
  .searchboxrow {
    height: 68px !important;
    margin-left: 57px;
  }
}

@media screen and (min-width: 150px) and (max-width: 599px) {
  .Dropdown-root {
    display: none;
  }

  .dashboardnavbar {
    /* background-color: burlywood; */
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
  }

  .filtros {
    position: relative;
    left: 19px;
    top: 42px;
    color: #0033a0;
    font: 12px/18px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .welcomBack {
    font: 18px/22px exraBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #292730;
    letter-spacing: 0px;
    /* position: fixed; */
    opacity: 1;
    top: 0;
    /*margin: 28px 29px;*/
    z-index: 1;
  }

  .clander {
    width: 103px !important;
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    margin: -15px -52px !important;
    z-index: 1 !important;
  }

  .dropdownArrow {
    display: none !important;
  }

  .dashboardSecondmainDiv {
    width: 409px !important;
    /* margin: 0 61px !important; */
  }

  .emojiDiv {
    background-color: #dbe0ff;
    width: 388px;
    height: 158px;
    border-radius: 18px;
    opacity: 1 !important;
    margin: 42px 0;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    top: 19px;
  }

  .crossSymbol {
    position: relative;
    left: 310px;
    top: 7px;
  }

  .emojisize {
    width: 58px !important;
  }

  /* .emojiStyl {
    width: 37px;
    margin: 0px auto;
  } */

  .yourDoingGreat {
    margin-top: 7px;
    color: #292730;
    font: 24px/29px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    height: 87px;
    display: flex;
    align-items: center;
  }

  .SkillsDiv {
    width: 100% !important;
    display: block;
    height: 210px;
  }

  .yourStrongestSkillText {
    color: #292730;
    width: 45%;
    font: 20px/25px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    line-height: 28px;
    padding: 4% 4% 6% 5%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flexCompetencias {
    width: 100%;
    position: relative;
    bottom: 50px;
    padding-left: 5%;
  }

  .contenedoresCompetencias {
    width: 30%;
    padding: 6% 0%;
  }

  .courageText {
    font: 14px/29px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #292730;
    position: relative;
    text-align: center;
  }

  .firstcircleBluesecond,
  .circleBlueSecond,
  .SecondcircleDiv,
  .secondCircle,
  .thirdcircleDiv,
  .thirdcircle {
    height: 70px;
    width: 70px;
  }

  .firstcircleBluesecond,
  .SecondcircleDiv,
  .thirdcircleDiv {
    margin: 5% 22%;
  }

  .blueCirclecount,
  .secondCicleCount,
  .thirdcircleCount {
    width: 51px;
    margin: -47px 10px;
    font: 31px/27px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .dashboardThirdmainDiv {
    width: 100% !important;
    margin: 0px 0px 125px !important;
  }

  .totalVoicesDiv {
    width: 100%;
    position: relative;
    top: 20px;
    right: 0px;
  }

  .grayFace {
    margin: 3% 7% 0% 11% !important;
  }

  .DivTextDesc {
    width: 204px !important;
    margin: 7% 0% 0% 0% !important;
  }

  .totalVoicesReceived {
    width: 230px;
    margin: 0px 67px;
    position: relative;
    top: 13px;
    color: #292730;
    font: 20px/15px exraBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .giftImgDiv {
    width: 46px;
    margin: 35px 277px;
  }

  .wowDivText {
    width: 87px;
    margin: -77px 161px;
    font: 14px/15px NunitoRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    letter-spacing: 0px;
    color: #5061ce;
  }

  .totalVoicesCount {
    width: 47px;
    margin: 32px 65px;
    font: 38px/41px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    letter-spacing: -2.4px;
    color: #5061ce;
  }

  .upDownLinesDiv {
    width: 171px;
    margin: -27px 105px;
  }

  .content-layout {
    display: flex !important;
    /* background: aquamarine; */
    /* flex-direction: column !important;
  justify-content: flex-end !important; */
    /* width: 50%; */
  }

  .mainDivdashboard {
    /* margin: -28px -13px !important; */
    margin: 0px !important;
    width: 100% !important;
    /* box-sizing: border-box !important; */
    /* width: fit-content !important; */
    /* margin: -28px -13px  !important; */
    /* margin: -28px 82px !important;
        position: relative !important;
        left: -81px !important; */
  }

  .graphDiv {
    /* background-color: tomato; */
    width: 378px !important;
    height: auto !important;
    margin-bottom: 120px !important;
    margin-top: 39px !important;
    margin-left: 23px !important;
    overflow-x: scroll;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 18px !important;
  }

  .tweleveNumber {
    /* margin: -66px auto; */
    margin-top: -3.8rem;
    margin-bottom: -4rem;
    margin-left: -2.3rem;
  }

  .voiceReceved {
    margin: 72px 0px 0px -37px;
  }

  .FindaPartnerInput {
    width: 298px !important;
  }
}

@media screen and (min-width: 425px) and (max-width: 528px) {
  .filtros {
    /* background: antiquewhite; */
    position: relative;
    left: 32px;
    top: 42px;
    color: #0033a0;
    font: 12px/18px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .clander {
    /* background-color: antiquewhite; */
    width: 103px !important;
    position: fixed !important;
    top: 0 !important;
    right: 0 !important;
    margin: -15px -96px;
    z-index: 1 !important;
  }

  .welcomBack {
    font: 20px/20px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    letter-spacing: 0px;
    color: #292730;
    opacity: 1;
    top: 0;
    margin: 21px 0px;
    z-index: 1;
  }
}

@media screen and (min-width: 700px) and (max-width: 899px) {
  .graphDiv {
    /* background-color: tomato; */
    margin: 16px 48px !important;
    width: 417px !important;
    overflow-x: scroll;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 18px !important;
    /* overflow: scroll; */
  }

  .dropDownboxrow {
    height: 68px !important;
    padding-left: 79px;
    padding-top: 2px;
    position: relative;
    left: -39px;
  }

  .searchi {
    margin-left: -63px;
    fill: #5061ce;
  }

  .searchboxrow {
    height: 68px !important;
    margin-left: 41px;
  }
}

@media screen and (min-width: 600px) and (max-width: 700px) {
  .graphDiv {
    margin: 16px -40px !important;
    width: 393px !important;
    overflow-x: scroll;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 18px !important;
  }

  .FindaPartnerInput {
    line-height: 30px;
    border-radius: 50px;
    border: none;
    padding: 7px 22px;
    background: #dee3ff;
  }

  .searchicon {
    margin: 0 -2px;
    width: 18px;
    /* background-color: chartreuse; */
  }

  .Dropdown-root {
    /* position: relative; */
    width: 255px;
    margin: 0 -34px;
    top: 10px;
  }

  .dropdownArrow {
    /* background: antiquewhite; */
    /* width: 29px; */
    position: relative;
    /* display: block; */
    display: inline-block;
    left: 190px;
    top: -20px;
    transform: rotate(90deg);
  }

  .searchi {
    margin-left: -3px;
    fill: #5061ce;
  }

  .dropDownboxrow {
    height: 68px !important;
    padding-left: 119px;
    padding-top: 2px;
    position: relative;
    left: -58px;
  }

  .clander {
    /* background-color: antiquewhite; */
    width: 103px;
    position: relative;
    left: 157px;
    top: -65px;
  }

  .emojiDiv {
    background-color: #dbe0ff;
    width: 342px;
    height: 158px;
    border-radius: 18px;
    opacity: 1 !important;
    margin: 1px 12px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .dashboardSecondmainDiv {
    /* background-color: chartreuse; */
    margin: 0 41px;
    width: 405px;
  }

  .searchboxmainWrapper {
    display: flex;
    flex-wrap: wrap;
    margin-left: -13px;
  }

  .dashboardThirdmainDiv {
    width: 100%;
    margin: 40px 1px;
  }

  .SkillsDiv {
    background-color: #e6ecff;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 18px;
  }

  .yourStrongestSkillText {
    color: #292730;
    width: 40%;
    position: relative;
    font: 19px/29px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .firstcircleBluesecond {
    height: 62px;
    width: 62px;
  }

  .courageText {
    font: 13px/29px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #292730;
    position: relative;
    text-align: center;
  }
  .ColaborationText {
    font: 13px/29px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #292730;
    position: relative;
    text-align: center;
  }
  .inovationText {
    font: 13px/29px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #292730;
    position: relative;
    text-align: center;
  }

  .secondCircle {
    height: 62px;
    width: 62px;
  }

  .secondCicleCount {
    width: 47px;
    font: 25px/27px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #5061ce;
    text-align: center;
  }

  .SecondcircleDiv {
    height: 62px;
    width: 62px;
  }

  .thirdcircleDiv {
    height: 62px;
    width: 62px;
  }

  .totalVoicesDiv {
    background-color: #f8f8f8;
    width: 259px;
    border-radius: 18px;
    height: 150px;
    box-shadow: 0px 3px 6px #00000029;
    margin: 11px 0;
    margin: o;
  }
  /* .searchicon {
    margin: 0 232px;
    width: 18px;
    background-color: chartreuse;
    position: relative;
    top: -42px;
  } */
  /* .graphDiv {
        background-color: tomato;
        width: 69%;
        height: auto;
        margin: -27px -154px;
    } */
}

@media screen and (min-width: 701px) and (max-width: 899px) {
  .FindaPartnerInput {
    margin: 10px 0px;
    line-height: 30px;
    border-radius: 50px;
    border: none;
    padding: 7px 22px;
    width: 414px;
    background: #dee3ff;
  }

  .searchicon {
    margin: 0 -60px;
    width: 18px;
  }

  .Dropdown-root {
    width: 255px;
    margin: 0 32px;
    top: 10px;
  }

  /* .dropDownboxrow {
    height: 68px !important;
    padding-left: 35px;
    padding-top: 2px;
    position: relative;
    left: -49px;
} */
  .dropdownArrow {
    position: relative;

    display: inline-block;
    left: 259px;
    top: -20px;
    transform: rotate(90deg);
  }

  .clander {
    /* background-color: antiquewhite; */
    width: 103px;
    position: relative;
    left: 221px;
    top: -64px;
  }

  .emojiDiv {
    background-color: #dbe0ff;
    width: 342px;
    height: 158px;
    border-radius: 18px;
    opacity: 1 !important;
    margin: 2px 29px;
    position: relative;
    box-shadow: 0px 3px 6px #00000029;
    top: 0px;
  }

  .dashboardSecondmainDiv {
    width: 100%;
  }

  .dashboardThirdmainDiv {
    width: 100%;
    margin: 0px 0px;
  }

  .SkillsDiv {
    background-color: #e6ecff;
    max-width: 67vw;
    height: 141px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 18px;
    margin: 8px 13px;
  }

  .totalVoicesDiv {
    background-color: #f8f8f8;
    width: 309px;
    border-radius: 18px;
    height: 150px;
    margin: 9px 15px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .yourStrongestSkillText {
    color: #292730;
    /* background-color: beige; */
    width: 157px;
    position: relative;
    font: 18px/19px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .firstcircleBluesecond {
    margin: 5% auto;
    height: 62px;
    width: 62px;
  }

  .courageText {
    width: auto;
    font: 14px/29px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #292730;
  }

  .ColaborationText {
    /* background-color: lawngreen; */
    width: auto;
    font: 14px/29px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #292730;
  }

  .secondCircle {
    width: 62px;
    height: 62px;
  }

  .SecondcircleDiv {
    margin: 5% auto;
    height: 62px;
    width: 62px;
  }

  .thirdcircleDiv {
    margin: 5% auto;
    height: 62px;
    width: 62px;
  }

  .emojiDivVoz {
    background-color: #dbe0ff;
    width: 366px;
    height: 161px;
    border-radius: 18px;
    opacity: 1 !important;
    margin: 0px -16px;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    display: flex;
  }
}

@media screen and (min-width: 900px) and (max-width: 1265px) {
  .dashboardFourthMainDiv {
    width: 95%;
    margin: 0px 30px 50px 34px;
  }

  .emojiDiv {
    background-color: #dbe0ff;
    width: 342px;
    height: 158px;
    border-radius: 18px;
    opacity: 1 !important;
    margin: 6px 237px;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    top: 1px;
  }

  .divChart {
    width: auto;
  }

  .dashboardSecondmainDiv {
    overflow-x: auto;
    margin: 0px 17px !important;
    width: 94%;
  }

  .dashboardThirdmainDiv {
    width: 95%;
    margin: 0px 21px 25px;
  }

  .SkillsDiv {
    width: 93%;
  }

  .totalVoicesDiv {
    background-color: #f8f8f8;
    width: 249px;
    border-radius: 18px;
    height: 150px;
    right: 20%;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
  }

  .totalVoicesReceived {
    /* background-color: lightsalmon; */
    width: max-content;
    margin: 0px auto;
    position: relative;
    top: 16px;
    color: #292730;
    font: 14px/15px exraBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .giftImgDiv {
    /* background: aqua; */
    width: 46px;
    margin: 35px 186px;
  }

  .wowDivText {
    /* background-color: lime; */
    width: 87px;
    margin: -77px 91px;
    font: 14px/15px NunitoRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    letter-spacing: 0px;
    color: #5061ce;
  }

  .totalVoicesCount {
    /* background-color: lightskyblue; */
    width: 47px;
    margin: 32px 19px;
    font: 38px/41px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    letter-spacing: -2.4px;
    color: #5061ce;
  }

  .upDownLinesDiv {
    /* background: blueviolet; */
    width: 171px;
    margin: -27px auto;
  }

  .graphDiv {
    /* background-color: tomato; */
    width: 844px;
    height: auto !important;
    margin: 16px 56px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 18px !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1090px) {
  .emojiDivVoz {
    margin: 0px 88px 14px;
  }

  .dashboardSecondmainDiv {
    /* background-color: chartreuse; */
    margin: 0px 14px !important;
    width: 95%;
  }
  .divChart {
    width: auto;
  }
  .dashboardThirdmainDiv {
    width: 95%;
    margin: 0px 0px 50px 14px;
    overflow-x: scroll;
    height: 17%;
  }

  .totalVoicesDiv {
    background-color: #f8f8f8;
    width: 309px;
    border-radius: 18px;
    height: 150px;
    margin: 0px 109px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .SkillsDiv {
    background-color: #e6ecff;
    width: 638px;
    height: 150px;
    margin: 0px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 18px;
  }

  /* .firstcircleBluesecond {
    margin: 74px 242px;
  } */

  .courageText {
    /* background-color: hotpink; */
    width: auto;
    /* margin: -44px 250px; */
    font: 14px/29px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #292730;
    position: relative;
    /* top: 24px; */
  }

  .SecondcircleDiv {
    /* background-color: azure; */
    height: 62px;
    width: 62px;
    /* margin: 28px 366px; */
  }

  .thirdcircleDiv {
    /* background-color: aqua; */
    height: 62px;
    width: 62px;
    /* margin: 85px 487px; */
  }
}

@media screen and (min-width: 1100px) and (max-width: 1295px) {
  .flexFaceMonth {
    width: 45%;
    margin: 4% 3% 1vh;
  }

  .flexVoces {
    padding: 8% 3%;
    width: 60%;
  }

  .emojiDivVoz {
    background-color: #dbe0ff;
    width: 319px;
    min-width: 319px;
    height: 161px;
    border-radius: 18px;
    opacity: 1 !important;
    margin: 0px -27px 14px;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    /* top: 70px; */
    display: flex;
  }

  .crossSymbol {
    position: relative;
    left: 281px;
    top: 9px;
  }
  .circleBlue {
    width: 99px;
  }

  .pedirVozBtn {
    border: none;
    margin: 14px 0px;
    width: 130px;
    height: 40px;
    background: #4132b4 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font: 17px/24px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }

  .noVoces {
    color: #292730;
    font: 16px/24px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    width: min(71%, 200px);
  }

  .divChart {
    margin: 0px 17px;
    width: 95%;
  }

  .ant-alert-description {
    margin: 6px 13px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1295px) {
  .flexFaceMonth {
    width: 45%;
    margin: 4% 3% 1vh;
  }

  .flexVoces {
    padding: 8% 3%;
    width: 60%;
  }

  .emojiDivVoz {
    background-color: #dbe0ff;
    width: 319px;
    min-width: 319px;
    height: 161px;
    border-radius: 18px;
    opacity: 1 !important;
    margin: 0px -27px 14px;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    /* top: 70px; */
    display: flex;
  }

  .crossSymbol {
    position: relative;
    left: 281px;
    top: 9px;
  }
  .circleBlue {
    width: 99px;
  }

  .pedirVozBtn {
    border: none;
    margin: 14px 0px;
    width: 130px;
    height: 40px;
    background: #4132b4 0% 0% no-repeat padding-box;
    border-radius: 4px;
    font: 17px/24px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
  }

  .noVoces {
    color: #292730;
    font: 16px/24px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    width: min(71%, 200px);
  }

  .divChart {
    margin: 0px 17px;
    width: 95%;
  }

  .ant-alert-description {
    margin: 6px 13px;
  }
}

@media screen and (min-width: 1266px) and (max-width: 1295px) {
  .emojiDiv {
    background-color: #dbe0ff;
    width: 350px;
    height: 158px;
    border-radius: 18px;
    opacity: 1 !important;
    margin: 42px -54px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .dashboardSecondmainDiv {
    /* background-color: chartreuse; */
    margin: 0px 17px;
    width: 95%;
  }

  .divChart {
    margin: 0px 17px;
    width: 95%;
  }

  .dashboardThirdmainDiv {
    width: 95%;
    margin: 0px 17px 50px;
    /* margin: 0px 30px 50px 0px; */
  }

  .SkillsDiv {
    background-color: #e6ecff;
    width: 91%;
    height: 150px;
    margin: 0px 55px 0px 4px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 18px;
  }

  /* .firstcircleBluesecond {
    margin: 69px 242px;
  } */

  .SecondcircleDiv {
    height: 62px;
    width: 62px;
  }

  .thirdcircleDiv {
    height: 62px;
    width: 62px;
  }

  .totalVoicesDiv {
    background-color: #f8f8f8;
    width: 275px;
    margin: 0 -58px;
    border-radius: 18px;
    height: 150px;
    box-shadow: 0px 3px 6px #00000029;
    right: 0%;
    position: relative;
  }

  .totalVoicesReceived {
    /* background-color: lightsalmon; */
    width: max-content;
    margin: 0px auto;
    position: relative;
    top: 16px;
    color: #292730;
    font: 14px/15px exraBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .giftImgDiv {
    /* background: aqua; */
    width: 46px;
    margin: 35px 206px;
  }

  .wowDivText {
    /* background-color: lime; */
    width: 87px;
    margin: -77px 103px;
    font: 14px/15px NunitoRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    letter-spacing: 0px;
    color: #5061ce;
  }

  .totalVoicesCount {
    /* background-color: lightskyblue; */
    width: 47px;
    margin: 32px 21px;
    font: 38px/41px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    letter-spacing: -2.4px;
    color: #5061ce;
  }

  .upDownLinesDiv {
    /* background: blueviolet; */
    width: 171px;
    margin: -27px 51px;
  }

  .graphDiv {
    /* background-color: tomato; */
    width: 968px !important;
    /* max-width: 107% !important;
        width: 108% !important; */
    height: auto !important;
    margin: 16px 71px !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 18px !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1069px) {
  .graphDiv {
    /* background-color: tomato; */
    width: 567px;
    height: auto !important;
    margin: 16px 18px !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 18px !important;
  }
}

@media screen and (min-width: 1070px) and (max-width: 1249px) {
  .graphDiv {
    /* background-color: tomato; */
    width: 693px !important;
    height: auto !important;
    margin: 16px 17px !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 18px !important;
  }
}

@media screen and (min-width: 599px) and (max-width: 1536px) {
  .filtros {
    display: none !important;
  }
}
