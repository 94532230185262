@font-face {
  font-family: NunitoLight;
  src: url(../../../fonts/Nunito/Nunito-Light.ttf);
}

.closeButton {
  top: 261px;
  left: 435px;
  width: 24px;
  height: 24px;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent
    url("../../../../components/home/assets/close-24px.svg") 0% 0% no-repeat
    padding-box;
}
.modalHeaderIcon {
  position: absolute;
  top: 23px;
  left: 23px;
  width: 111px;
  height: 120px;
  opacity: 1;
}
.divHeaderImage {
  width: max-content;
  opacity: 1;
  text-align: left;
  font: 16px/20px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #5061ce;
  position: relative;
  left: 6px;
  max-width: 320px;
}
.divHeaderImageError {
  width: auto;
  opacity: 1;
  text-align: left;
  font: 16px/20px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #5061ce;
  position: relative;
  left: 6px;
}
.divHeaderExce {
  width: 335px;
  padding-right: 31px;
  opacity: 1;
  text-align: left;
  font: 16px/20px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #5061ce;
}

.divBodyText {
  margin-top: 6%;
}
.divBodyTextNoExce {
  margin: 5% 0% 2% 0%;
}

.modalcontent {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 515px;
  height: 244px;
  pointer-events: auto;
  border: 1px solid;
  outline: 0;
  border-color: rgba(0, 0, 21, 0.2);
  background: #dbe0ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 18px;
  opacity: 1;
}
.modalcontentNoExcelente {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 510px;
  height: 171px;
  pointer-events: auto;
  border: 1px solid;
  outline: 0;
  border-color: rgba(0, 0, 21, 0.2);
  background: #dbe0ff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 18px;
  opacity: 1;
}
.btn:hover {
  color: white;
  text-decoration: none;
}
.prueba {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 0px solid;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  border-color: #d8dbe0;
}
.modal-title {
  padding: 2% 0% 2% 32%;
  top: 1px;
  left: 184px;
  text-align: left;
  font: 16px/20px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #5061ce;
  width: auto;
  opacity: 1;
}
.modal-title-Excelente {
  padding: 1% 7% 7% 0%;
  top: 1px;
  left: 184px;
  text-align: left;
  font: 16px/20px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #5061ce;
  opacity: 1;
}

.modal-title-Cargando,
.modal-title-Cargando .modal-title {
  padding: 0%;
}

/* .closeButton {
  display: none;
} */

.modalBody {
  text-align: center;
  padding: 0;
}

.fade {
  opacity: 1;
}
