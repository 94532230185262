.wrapper {
  z-index: 1;
  position: relative;
}

.titlePara {
  font: 32px/70px nunitoExtrabold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  margin-bottom: 2px;
  color: #292730;
  opacity: 1;
  line-height: 150%;
}
.pillsTitle {
  width: min(100%, 800px);
  margin: 8px 0px;
  font: 20px/25px nunitoExtrabold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #4132B4;
  opacity: 1;
}
@media screen and (max-width: 600px) {
  .searchInputWrapper {
    display: flex;
    width: 100%;
  }
}
@font-face {
  font-family: CabinRegular;
  src: url(./../../../fonts/Cabin/Cabin-Regular.ttf);
}
.error {
  text-align: left;
  font: 12px/19px CabinRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #A51A1A;
  opacity: 1
}

