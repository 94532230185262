@font-face {
  font-family: ligntNunito;
  src: url(../../fonts/Nunito/Nunito-Light.ttf);
}

@font-face {
  font-family: BoldNunito;
  src: url(../../fonts/Nunito/Nunito-Bold.ttf);
}

.ant-alert-with-description.ant-alert-no-icon {
  border: none;
  padding: 14px 8px;
  width: 638px;
  height: 161px;
  background-color: #e3e829;
  border-radius: 18px;
  background-image: url(../../home//assets/ejemplo-ilustracion.svg) !important;
  background-repeat: no-repeat;
  background-size: 131px 131px;
  background-position: 456px !important;
  margin-bottom: 14px;
  box-shadow: 0px 3px 6px #00000029;
}

.ant-alert.ant-alert-no-icon .ant-alert-close-icon {
  background-image: url(../../home//assets/close-25px.svg) !important;
}

.ant-alert-with-description .ant-alert-close-icon {
  background-position-y: -3px;
  background-position-x: -5px;
}

.anticon svg {
  visibility: hidden;
  height: 1.2em;
  margin-right: 10px;
}

.ant-alert-description {
  display: none;
  white-space: pre-line;
  margin: 6px 28px;
  font: 18px/24px ligntNunito, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: #0033a0;
  letter-spacing: 0px;
  opacity: 1;
  width: min(100%, 370px);
}

.ant-alert-with-description .ant-alert-message {
  display: block;
  /* margin-bottom: 4px; */
  /* color: rgba(0, 0, 0, 0.85); */
  margin: -8px 28px;
  color: #00aec7;
  font: 30px/70px BoldNunito, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

@media screen and (min-width: 901px) and (max-width: 1089px) {
  .ant-alert-with-description.ant-alert-no-icon {
    border: none;
    padding: 15px 11px;
    width: 598px !important;
    height: 161px;
    background-color: #e3e829;
    border-radius: 18px;
    background-image: url(../../home//assets/ejemplo-ilustracion.svg);
    background-repeat: no-repeat;
    background-size: 131px 131px;
    background-position: 438px !important;
    margin: 0px !important;
    box-shadow: 0px 3px 6px #00000029;
  }

  .welcomBack {
    font: 27px/61px exraBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #292730;
    letter-spacing: 0px;
    opacity: 1;
    margin: 7px 0px;
  }

  .emojiDiv {
    background-color: #dbe0ff;
    width: 342px;
    height: 158px;
    border-radius: 18px;
    opacity: 0.52;
    margin: 26px 238px;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    top: 8px;
  }
}

@media screen and (min-width: 701px) and (max-width: 899px) {
  .welcomBack {
    font: 28px/70px exraBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #292730;
    letter-spacing: 0px;
    opacity: 1;
    /* margin: -19px 32px; */
  }

  .ant-alert-with-description.ant-alert-no-icon {
    border: none;
    padding: 14px 8px;
    width: 437px;
    height: 161px;
    background-color: #e3e829;
    border-radius: 18px;
    background-image: url(../../home//assets/ejemplo-ilustracion.svg);
    background-repeat: no-repeat;
    background-size: 105px 105px;
    background-position: 301px !important;
    margin: 0px !important;
    box-shadow: 0px 3px 6px #00000029;
  }

  .ant-alert-with-description .ant-alert-message {
    display: block;
    margin: -6px 19px;
    color: #00aec7;
    font: 30px/70px BoldNunito, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .ant-alert-description {
    white-space: pre-line;
    margin: 0px 6px;
    font: 18px/24px ligntNunito, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #0033a0;
    letter-spacing: 0px;
    opacity: 1;
    width: min(100%, 370px);
  }

  .alertcomponentDiv {
    margin: 11px 0 !important;
    position: relative;
    top: -7px;
  }
}

@media screen and (min-width: 1090px) and (max-width: 1266px) {
  .ant-alert-with-description.ant-alert-no-icon {
    border: none;
    padding: 15px 11px;
    width: 596px;
    height: 161px;
    background-color: #e3e829;
    border-radius: 18px;
    background-image: url(../../home//assets/ejemplo-ilustracion.svg);
    background-repeat: no-repeat;
    background-size: 131px 131px;
    background-position: 430px !important;
    margin: 0px !important;
    box-shadow: 0px 3px 6px #00000029;
  }

  .welcomBack {
    font: 31px/60px exraBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #292730;
    letter-spacing: 0px;
    opacity: 1;
    margin: 7px 0px;
  }

  .emojiDiv {
    background-color: #dbe0ff;
    width: 342px;
    height: 158px;
    border-radius: 18px;
    opacity: 0.52;
    margin: 26px 238px;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    top: 8px;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1295px) {
  .ant-alert-with-description.ant-alert-no-icon {
    /* min-width: 579px; */
    width: 97%;
    background-position: 406px !important;
  }

  .ant-alert-description {
    margin: 6px 13px;
  }

  .ant-alert-with-description .ant-alert-message {
    margin: -8px 13px;
  }
}

@media screen and (min-width: 1267px) and (max-width: 1295px) {
  .ant-alert-with-description.ant-alert-no-icon {
    border: none;
    padding: 15px 11px;
    width: 95%;
    height: 161px;
    background-color: #e3e829;
    border-radius: 18px;
    background-image: url(../../home//assets/ejemplo-ilustracion.svg);
    background-repeat: no-repeat;
    background-size: 131px 131px;
    background-position: 415px !important;
    margin: 0px !important;
    box-shadow: 0px 3px 6px #00000029;
  }

  .emojiDivVoz {
    background-color: #dbe0ff;
    width: 336px;
    height: 161px;
    border-radius: 18px;
    opacity: 1 !important;
    margin: 0px -41px 14px;
    box-shadow: 0px 3px 6px #00000029;
    position: relative;
    display: flex;
  }

  .crossSymbol {
    /* background: aqua; */
    position: relative;
    left: 296px;
    top: 9px;
  }

  .emojiStyl {
    /* background: aliceblue; */
    width: 55px;
    /* margin: 20px 176px; */
    margin: -171px 183px;
  }

  .yourDoingGreat {
    color: #292730;
    /* background: aliceblue; */
    font: 19px/20px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    width: 106px;
    /* margin: -15px 168px; */
    margin: 174px 171px;
  }

  .welcomBack {
    font: 32px/70px exraBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #292730;
    letter-spacing: 0px;
    opacity: 1;
    margin: 7px 0px;
  }
}

@media screen and (min-width: 600px) and (max-width: 699px) {
  .welcomBack {
    font: 32px/70px exraBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #292730;
    letter-spacing: 0px;
    opacity: 1;
    margin: -19px 6px;
  }

  .ant-alert-with-description.ant-alert-no-icon {
    border: none;
    padding: 15px 11px;
    width: 386px;
    height: 120px;
    background-color: #e3e829;
    border-radius: 18px;
    background-image: url(../../home//assets/ejemplo-ilustracion.svg);
    background-repeat: no-repeat;
    background-size: 93px 93px;
    background-position: 255px !important;
    margin: 0 28px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .ant-alert-with-description .ant-alert-message {
    display: block;
    margin: -15px 3px;
    color: #00aec7;
    font: 30px/70px BoldNunito, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .ant-alert-description {
    white-space: pre-line;
    margin: 0px 5px;
    font: 18px/24px ligntNunito, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #0033a0;
    letter-spacing: 0px;
    opacity: 1;
    width: min(100%, 370px);
  }

  .alertcomponentDiv {
    margin: 11px -21px !important;
    position: relative;
    top: -7px;
    margin-bottom: -21px !important;
  }
}

@media screen and (min-width: 150px) and (max-width: 599px) {
  .ant-alert-with-description.ant-alert-no-icon {
    border: none;
    padding: 15px 11px;
    width: 347px;
    height: 161px;
    background-color: #e3e829;
    border-radius: 18px;
    background-image: url(/static/media/ejemplo-ilustracion.b307560c.svg);
    background-repeat: no-repeat;
    background-size: 85px 157px !important;
    background-position: 255px !important;
    box-shadow: 0px 3px 6px #00000029;
  }

  .ant-alert-description {
    display: none;
    white-space: pre-line;
    margin: 6px 0px;
    font: 12px/24px ligntNunito, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #0033a0;
    letter-spacing: 0px;
    padding-right: 76px !important;
    opacity: 1;
    width: min(100%, 370px);
  }

  .ant-alert-with-description .ant-alert-message {
    display: block;
    margin: -13px 3px;
    color: #00aec7;
    font: 20px/70px BoldNunito, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .alertcomponentDiv {
    margin: 19px -56px !important;
  }

  .emojiDivVoz {
    width: 361px !important;
    height: 161px !important;
    margin: 0px -54px !important;
    top: -6px !important;
  }

  .april2020 {
    width: auto;
  }

  p.descripcion {
    line-height: 17px !important;
    width: 183px;
  }

  .txtCompetenciaVacio {
    font: 17px/0px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
    color: #4d4cac;
    opacity: 1;
    /* right: 180px; */
    /* width: 396%; */
  }

  .flexCompetenciaVacio {
    display: flex;
    width: 100%;
    /* padding: 0; */
    margin-left: -288px;
  }

  .contenedorCompetenciaVacio {
    /* width: 100%; */
    padding: 17% 3% 0% 0%;
    /* display: flex; */
  }

  .divimgCompetenciaVacio {
    margin: 14% 36% 0% 2%;
    height: 0px;
    width: 0px;
  }
}
