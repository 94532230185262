.wrapper {
  width: 100%;
  margin: auto;
  background-color: #fff;
  /* padding: 0px 5px; */
  /* border-bottom: 3px solid rgb(160, 160, 160); */
}
.titleWrapper {
  /* background-color: inherit; */
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f4f4f4;
  color: #4132b4;
  outline: none;
  border: none;
}
.titleWrapper:focus {
  outline: none;
}

.titleWrapper span {
  font-size: 1rem;
  font-family: "Lato", sans-serif;
  /* font-weight: 700; */
}
.titleWrapper svg {
  width: 26px;
  height: 26px;
}

.expandWrapper {
  transition: height 100ms linear 0s;
  overflow: hidden;
}
