.flexPageError {
  display: flex;
  padding: 3% 1% 4% 4%;
}

.parentDiv {
  width: 84%;
}

.flexImg {
  width: 43%;
}

.flexImg img {
  width: 100%;
  height: 456px;
}

.flexTxt {
  padding: 9% 3% 8% 6%;
  width: 57%;
}

.flexTxt p:first-child {
  width: auto;
  height: 80px;
  text-align: left;
  letter-spacing: 0px;
  color: #00aec7;
  opacity: 1;
  font: normal normal calc(1em + 7vw) / 37px NunitoBold, Helvetica, Arial,
    Verdana, Tahoma, sans-serif;
}

.flexTxt p:nth-child(2) {
  padding-left: 6px;
  margin-bottom: 2%;
  width: 100%;
  height: fit-content;
  text-align: left;
  letter-spacing: 0px;
  color: #292730;
  opacity: 1;
  font: normal normal calc(1em + 1vw) / 37px NunitoBold, Helvetica, Arial,
    Verdana, Tahoma, sans-serif;
}

.dashboardBtn {
  border: none;
  margin: 14px 6px;
  width: 18vw;
  min-width: 230px;
  height: fit-content;
  min-height: calc(1em + 3.5vh);
  background: #4132b4 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font: 18px/24px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.dashboardBtn:hover {
  color: #fff;
  background-color: #00aec7 !important;
  border-color: #00aec7;
}

.dashboardBtn a {
  text-align: center;
  font: normal normal 18px/24px NunitoBold, Helvetica, Arial, Verdana, Tahoma,
    sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

@media screen and (min-width: 770px) and (max-width: 1000px) {
  .flexTxt {
    padding: 6% 3% 8% 3%;
  }

  .flexTxt p:first-child {
    height: 55px;
    font: normal normal calc(1em + 6vw) / 37px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }

  .dashboardBtn{
    width: 32vw;
    position: relative;
    /* left: 103px; */
  }
}