#mainWrapper {
  background-color: #f4f9ff;
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
}
.dashboardnavbar {
  /* background-color: burlywood; */
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.logoDiv {
  /* background: aqua; */
  width: 127px;
  height: 75px;
  margin: 0 31px;
}
.logoStyl {
  padding: 10px 5px;
  margin-top: 7px;
  height: 78px;
}
.notificaiondiv {
  position: absolute;
  /* background-color: darkgray; */
  margin: -75px 77%;
  padding: 13px 0;
}

.spanElipsedot {
  display: block;
  margin: -52px -7px;
}
.ElipsedoticonStyle {
  width: 13px;
  position: relative;
  left: 41px;
  top: -1px;
}

/* ////////////   Responsive sidebar*/
@media screen and (max-width: 700px) {
  /* .maindivSidebar {
    background-color: #4132b4;
    width: 157px;
    height: 641px;
    border-radius: 0px 36px 36px 0px;
    opacity: 1;
  } */

  .userText1 {
    /* background: aliceblue; */
    position: relative;
    left: 7px;
    top: -12px;
    color: #00aec7;
    font: 16px/70px nunitoExtrabold, Helvetica, Arial, Verdana, Tahoma,
      sans-serif !important;
    opacity: 0.7;
    font-size: -16px;
    letter-spacing: 0px;
    /* height: 58px; */
    width: 96px;
  }
  .userText2 {
    position: relative;
    top: -36px;
    left: -7px;
    color: #e6ecff;
    text-align: justify;
    opacity: 0.7;
    font: 12px/17px openSans, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }
}
@media screen and (min-width: 150px) and (max-width: 599px) {
  /* .notificaiondiv {
        margin: -34px 13px !important;
        padding: 0px 0 !important;
       
    } */
  .notificaiondiv {
    margin: -26px 19px !important;
    padding: 0px 0 !important;
    position: fixed;
    /* z-index: 1 !important; */
    right: 0;
  }
  .notification_circledStyle {
    width: 39px;
  }
  .spanElipsedot {
    display: block;
    margin: -43px -14px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1090px) {
  .notificaiondiv {
    margin: -75px 73%;
  }
}

.content-layout {
  display: flex;
}
