body {
  background: #fff;
  color: #4132b4;
  width: 100%;
}

.container2 {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 20px 0px 30px 0px;
  left: 0%;
  box-shadow: 1px 3px 1px 1px #b3afaf31;
  border-radius: 18px 18px 18px 18px;
  font: 18px nunitoExtrabold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.bloc-tabs2 {
  display: flex;
}

.tabs2 {
  outline: none !important;
  padding: 0px 0px 0px 0px;
  border-radius: 18px 18px 0px 0px;
  text-align: center;
  width: 100%;
  position: relative;
  color: #929292;
  box-sizing: border-box;
  box-shadow: 1px 0px 1px 1px #b3afaf31;
  
}
.active-tabs2  {
  background: white;
  color: #4132b4;
  width: 100%;
  height: 50px;
}

.active-tabs2::before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  height: 5px;
  font: 18px nunitoExtrabold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

.content2-tabs {
  flex-grow : 1;
}
.content2 {
  background: white;
  padding: 15px 15px 15px 10px;
  width: 100%;
  height: 30%;
  display: none;
  font: 18px nunitoExtrabold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
.content2 p {
  width: 100%;
  height: 50%;
}
.active-content2 {
  display: block;
  padding: 0px 15px 0px 15px;
  width: 100%;
  height: 70%;
}