@font-face {
  font-family: NunitoBold;
  src: url(../../../fonts/Nunito/Nunito-Bold.ttf);
}
@font-face {
  font-family: NunitoLight;
  src: url(../../../fonts/Nunito/Nunito-Light.ttf);
}
@font-face {
  font-family: NunitoRegular;
  src: url(../../../fonts/Nunito/Nunito-Regular.ttf);
}

#chart {
  /* max-width: 100% !important; */
  width: 100% !important;
  padding: 10px;
  margin: 9px 0px !important;
  background: #f8f8f8 !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 18px !important;
  height: 470px !important;
  /* width: 1046px !important;
    background: #F8F8F8 !important;
    box-shadow: 0px 3px 6px #00000029 !important; 
    border-radius: 18px !important; */
  /* padding-left: 10px; Mirar */
}

/* g[fill="#4D4CAC"] rect {
  fill: black;
  z-index: 1;
}
g[fill="#5D7FF1"] rect {
  fill: blue;
  z-index: -1;
}
g[fill="#FF808B"] rect {
  fill: blueviolet;
  z-index: -2;
} */

@media screen and (min-width: 150px) and (max-width: 599px) {
  #chart {
    width: 1024px !important;
    max-width: 1067px !important;
  }
  /* #chart {
        
        height: 470px !important;
        width: 387px !important;
        background: #F8F8F8 !important;
        box-shadow: 0px 3px 6px #00000029 !important;
        border-radius: 18px !important;
    } */
}
@media screen and (min-width: 600px) and (max-width: 700px) {
  #chart {
    width: 601px !important;
    max-width: 590px !important;
  }
}
@media screen and (min-width: 700px) and (max-width: 899px) {
  #chart {
    max-width: 100% !important;
    width: 100% !important;
  }
}
@media screen and (min-width: 1250px) and (max-width: 1295px) {
  #chart {
    max-width: 100% !important;
    width: 100% !important;
    margin: 9px 3px 24px !important;
    background: #f8f8f8 !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 18px !important;
    margin-left: 6px;
  }
}
@media screen and (min-width: 1070px) and (max-width: 1249px) {
  #chart {
    margin: 9px 3px 24px !important;
    background: #f8f8f8 !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 18px !important;
  }
}
@media screen and (min-width: 900px) and (max-width: 1069px) {
  #chart {
    max-width: 100% !important;
    width: 100% !important;
    /* margin: 24px 72px !important; */
    background: #f8f8f8 !important;
    box-shadow: 0px 3px 6px #00000029 !important;
    border-radius: 18px !important;
    /* height: 470px !important;
        width: 635px !important;
        margin: 0 -71px;
        background: #F8F8F8 !important;
        box-shadow: 0px 3px 6px #00000029 !important;
        border-radius: 18px !important; */
  }
}

@media screen and (min-width: 150px) and (max-width: 599px) {
  /* #chart {
                height: 470px !important;
                width: 387px !important;
                background: #F8F8F8 !important;
                box-shadow: 0px 3px 6px #00000029 !important;
                border-radius: 18px !important;
            } */
}
/* rect {
  width: 22px;
} */
.dxc-arg-elements text {
  letter-spacing: 0px;
  text-align: center;
  font: 11px/70px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
  opacity: 1;
  color: #66637b;
}

.dxc-title text {
  padding-top: 30px !important;
  font: 24px/29px NunitoLight, Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
  text-align: center;
  letter-spacing: 0px;
  color: #292730;
  opacity: 1;
}

.dxl-marker text {
  font: 14px/29px NunitoRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
  text-align: center;
  letter-spacing: 0px;
  color: #292730;
  opacity: 1;
}

.dx-loading-indicator text {
  letter-spacing: 0px;
  text-align: center;
  font: 20px/29px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif !important;
  opacity: 0.4;
  color: #66637b;
}

@media screen and (max-width: 1295px) {
  /* #chart {
        height: 470px !important;
       
        width: 973px !important;
        background: #F8F8F8 !important;
        box-shadow: 0px 3px 6px #00000029 !important;
        margin: 0 -49px !important;
        border-radius: 18px !important;
    } */
}
/* @media screen and (max-width:1100px){
    #chart {
        height: 470px !important;
      
        background: #F8F8F8 !important;
        box-shadow: 0px 3px 6px #00000029 !important;
        margin: 0 -49px !important;
        border-radius: 18px !important;
    }
}
@media screen and (max-width:970px){
    #chart {
        height: 470px !important;
       
        background: #F8F8F8 !important;
        box-shadow: 0px 3px 6px #00000029 !important;
        margin: 0 -49px !important;
        border-radius: 18px !important;
    }
}
@media screen and (max-width:842px){
    #chart {
        height: 470px !important;
       
        background: #F8F8F8 !important;
        box-shadow: 0px 3px 6px #00000029 !important;
        margin: 0 -49px !important;
        border-radius: 18px !important;
    }
} */
