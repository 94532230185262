@font-face {
  font-family: NunitoRegular;
  src: url(../../../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoBold;
  src: url(./../../../fonts/Nunito/Nunito-Bold.ttf);
}
@font-face {
  font-family: CabinRegular;
  src: url(./../../../fonts/Cabin/Cabin-Regular.ttf);
}
@font-face {
  font-family: openSans;
  src: url(./../../../fonts/Open_Sans/OpenSans-Regular.ttf);
}

.emoji {
  margin: 0.6% 0%;
  width: 30px;
  height: 30px;
}

.parentFlex {
  margin-bottom: 3.5%;
}

.divFlex {
  display: flex;
  margin-top: 3%;
}

.divFlex2 {
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 2%;
}

.tituloComentario {
  width: auto;
  color: #4132b4;
  opacity: 1;
  font: 18px/24px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: center;
  margin: 2vh 1vw;
  text-decoration: underline;
}
.tituloCompetenciaExcelente {
  width: auto;
  color: #4132b4;
  opacity: 1;
  font: 20px/25px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: center;
  margin: auto 1.5%;
}

.tituloCompetenciaBueno {
  width: auto;
  letter-spacing: 0px;
  color: #5d7ff1;
  opacity: 1;
  font: 20px/25px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: center;
  margin: auto 1.5%;
}

.tituloCompetenciaAdecuado {
  width: auto;
  letter-spacing: 0px;
  color: #FE9900;
  opacity: 1;
  font: 20px/25px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: center;
  margin: auto 1.5%;
}

.tituloCompetenciaMejorar {
  width: auto;
  letter-spacing: 0px;
  color: #ff808b;
  opacity: 1;
  font: 20px/25px NunitoBlack, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: center;
  margin: auto 1.5%;
}

.iconUser {
  background-color: transparent;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  height: 60px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  margin-top: auto;
  margin-bottom: auto;
}

.photoCell {
  object-fit: cover;
  width: 100%;
  max-width: 60px;
  max-height: 60px;
  min-height: 60px;
}

.srchTitleWraper {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  max-width: calc(100% - 60px);
}
.srchTitleWraper p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.srchTitleWraper p:first-child {
  font: normal normal normal 16px/15px openSans, Helvetica, Arial, Verdana,
    Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #5061ce;
  opacity: 1;
  margin-bottom: 2%;
}
.srchTitleWraper p:nth-child(2) {
  /* line-height: 18px; */
  color: #292730;
  font: normal normal bold 24px/28px NunitoBold, Helvetica, Arial, Verdana,
    Tahoma, sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  text-transform: lowercase !important;
  max-width: 360px;
}
.srchTitleWraper p:nth-child(2):first-line {
  color: black;
  font: normal normal bold 24px/28px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  text-transform: capitalize !important;
}

.dateTable {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  max-width: calc(100% - 60px);
}
.dateTable p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dateTable p:first-child {
  font: normal normal normal 16px/15px openSans, Helvetica, Arial, Verdana,
    Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #5061ce;
  opacity: 1;
  margin-bottom: 2%;
}
.dateTable p:nth-child(2) {
  /* line-height: 18px; */
  color: black;
  font: 16px/28px NunitoRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  opacity: 1;
}

.date {
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: black;
}

.fila {
  height: 128px;
}

.pillsWrapper {
  margin: 12px auto;
  display: flex;
  flex-wrap: wrap;
  margin-left: -6px;
}

.contenedorComentario {
  margin: 22px 0px;
}

.contenedorComentario2 {
  margin: 22px 0px;
  letter-spacing: 0px;
  color: #292730;
  opacity: 1;
  font: 16px/22px CabinRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: left;
}

.divComentario {
  margin-left: 4.5%;
  line-height: 1.7em;
  color: #909090;
}

.divComentario div {
  color: #292730;
  white-space: pre-wrap;
}

.pComentario {
  color: #00aec7;
  text-decoration: underline;
}

.pComentario:hover {
  color: #4132b4;
  text-decoration: underline;
}

.pillButton {
  padding: 8px 18px;
  border-radius: 24px;
  border: 1px solid #4132b4;
  font: 14px/21px CabinRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  background-color: transparent;
  font-weight: 500;
  cursor: pointer;
  margin: 6px;
}
.pillButton:active,
.pillButton:focus {
  outline: none;
}
.pillButton:active {
  background-color: #00aec7;
  border-color: #00aec7;
  color: white;
}
.pillButton2 {
  padding: 8px 18px;
  border-radius: 24px;
  border: 1px solid #4132b4;
  background-color: transparent;
  font-weight: 500;
  cursor: auto !important;
  margin: 6px;
}
.pillButton2:active,
.pillButton2:focus {
  outline: none;
}

.checkBoxPillWrapper,
.fieldShowTitle {
  display: flex;
  align-items: flex-start;
  margin: 1.2rem auto;
}
.checkBoxPillWrapper input {
  min-width: 26px;
  width: 26px;
  height: 26px;
  /* background-image: url(./../../assets/check_box-24px.svg) !important;
background-repeat: no-repeat !important;
background-size: 23px; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  /* padding: 1px; */
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #4132b4;
  /* border: 2px solid transparent; */

  /* background-color: #e7e6e7; */
  border-radius: 20%;
  margin-right: 12px;
}

/* appearance for checked radiobutton */
.checkBoxPillWrapper input:checked {
  /* background-color: #4132b4; */
  background-image: url(./../../assets/check_box-24px.svg) !important;
  background-repeat: no-repeat !important;
  background-size: 30px;
  background-position: center; /* 0px 0px; */
  vertical-align: middle;
}
.checkBoxPillWrapper input:focus {
  outline: #4132b4;
}

.checkBoxPillWrapper p,
.fieldShowTitle p {
  /* width: min(100%, 470px); */
  margin: 0px;
  font: 20px/25px nunitoExtrabold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #4132b4;
  opacity: 1;
}
.fieldShowTitle {
  align-items: center;
  margin-bottom: 1rem;
}
.fieldShowTitle img {
  margin-right: 12px;
}

.listItemOne {
  margin: 0 auto; /* requerido para alineación horizontal */
  /* width: 941px; */
}

/* SKIll list css */
.skillListContainer {
  display: flex;
  margin-left: -12px;
  /* max-width: 905px; */
  letter-spacing: 0px;
  overflow-x: scroll;
  width: auto;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #c2c9d2;
}

.skillList {
  border-radius: 16px 16px 24px 24px;
  min-height: 280px;
  /* width: 215px; */
  width: 100%;
  border: 2px solid #4132b4;
  overflow: hidden;
  margin: 0px 12px;
  display: flex;
  flex-direction: column;
  height: 0%;
  max-width: 100%;
  min-width: 277px;
}
.listTitleWrapper {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 24px 24px;
  padding: 10px 0px;
  font: 24px/29px NunitoRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
.listTitleWrapper p {
  text-align: center;
  margin: 0px;
  opacity: 1;
  font-size: 1.2rem;
  color: white;
}

.ulWrapper {
  width: 100%;
  list-style: none;
  min-height: calc(100% - 50px);
  background-color: white;
  padding: 10px 8px;
  overflow-y: scroll;
  flex-grow: 1;
  margin-bottom: 0;
  overflow: hidden;
}

.listItem {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 24px;
  background-color: #00aec7;
  color: white;

  padding: 0px 8px 0px 4px;
  height: auto;
  position: relative;
  margin-bottom: 4px;
}
.listItem span {
  padding: 8px;
  cursor: pointer;
  padding-right: 0px;
}
.closeIcon {
  margin-left: auto;
  border-radius: 50%;
  padding: 2px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.closeIcon:active {
  /* fill: rgb(253, 77, 77); */
  background-color: rgba(0, 0, 0, 0.1);
}

.inputSendFields {
  width: 100%;
  padding: 1rem;
  border-radius: 12px;
  border: 2px solid #858484;
  font: 16px/21px CabinRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  color: #858484;
  background-color: #f4f9ff;
  box-shadow: 0px 3px 6px #00000029;
}
.inputSendFields::placeholder {
  color: #909090;
  opacity: 1;
}

.inputSendFields:focus {
  outline: none;
  border-color: #4132b4;
  color: #4132b4;
}
.commentTxtAreaFull:focus {
  box-shadow: 0px 3px 16px #a7a7a729;
}

/* .inputSendFields:active {
  outline: none;
} */

/* Submit buttons wrappers */

.submitButtonsWrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  margin-top: 35px;
}

.submitButtonsWrapper button {
  width: 160px;
  height: 35px;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
}
.submitButtonsWrapper button:hover {
  background-color: #00aec7;
  border: #00aec7;
}
.submitButtonsWrapper button:focus {
  outline: none;
}

.enviarBtn {
  border: 1px solid #4132b4;
  background-color: #4132b4;
  color: white;
  width: 178px;
  height: 40px;
  font: 18px/24px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  text-align: center;
  margin-top: 3%;
}

.submitionBtn {
  border: 1px solid #4132b4;
  position: relative;
  bottom: 5px;
  color: #ffffff;
  width: 146px;
  height: 40px;
  background: #4132b4 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font: 18px/24px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
.submitionBtn:active {
  background-color: #00aec7 !important;
}

.cancelBtn {
  margin-right: 4%;
  position: relative;
  bottom: 5px;
  border: 1px solid #00aec7;
  background-color: transparent;
  color: #00aec7;
  width: 216px;
  height: 40px;
  text-align: center;
  font: 18px/24px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  border-radius: 4px;
}

/* skills list view for mobile */
.mobileListContainer {
  display: none;
  width: 100%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
}

.elementOptionDiv {
  display: flex;
  justify-content: space-evenly;
  height: 64px;
}

.elementOptItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.elementOptItem span {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.elementOptItem p {
  margin: 0px;
  font-size: 12px;
}
/* MEdia queries */
@media screen and (max-width: 400px) {
  .submitButtonsWrapper {
    flex-direction: column;
  }
  .submitButtonsWrapper button {
    width: 100%;
    margin: 4px 0px;
  }
}

@media screen and (max-width: 600px) {
  .skillListContainer {
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
    display: none;
  }
  .skillListContainer > div {
    width: 100%;
    margin-bottom: 12px;
  }
  .pillsWrapper {
    display: none;
  }

  .mobileListContainer {
    display: flex;
    flex-direction: column;
  }
}
