@font-face {
  font-family: openSansRegular;
  src: url(../../fonts/Open_Sans/OpenSans-Regular.ttf);
}
@font-face {
  font-family: openSansLight;
  src: url(../../fonts/Open_Sans/OpenSans-Light.ttf);
}

.sideDrawer {
  /* background: aqua; */
  position: fixed;
  right: 0%;
  z-index: 1;
  bottom: 50%;
}

/* @media (min-height: 630px) and (max-height: 730px){ */
@media screen and (min-height: 630px) {
  .sideDrawer {
    bottom: 80%;
  }
}

.drwerDivBtn {
  background-color: #00aec7;
  width: 128px;
  height: 40px;
  position: relative;
  transform: rotate(-90deg);
  top: 43px;
  border-radius: 18px 18px 0px 0px;
  margin: 0 -44px;
  box-shadow: -10px -5px 35px #00000029;
  transition-property: margin;
  transition-duration: 0.3s;
}
.drwerDivBtnMove {
  background-color: #00aec7;
  width: 125px;
  height: 46px;
  position: relative;
  transform: rotate(-90deg);
  top: 43px;
  border-radius: 18px 18px 0px 0px;
  margin: 0 -336px;
  box-shadow: -10px -5px 35px #00000029;
  transition-property: margin;
  transition-duration: 0.3s;
}
/* .drwerDivBtn5 {
    background-color: #00AEC7;
    width: 120px;
    height: 40px;
    position: relative;
    transform: rotate(-90deg);
    top: 19px;
    left: -259px;
    border-radius: 18px 18px 0px 0px;
    margin: 0 -40px;
    box-shadow: -10px -5px 35px #00000029;
} */
.drwerDivBtn1 {
  /* background-color: #00AEC7; */
  width: 26px;
  position: fixed;
  top: 0;
  left: 0;
  margin: 25px 11px;
  cursor: pointer;
}
.drwerBtnLineStyle {
  height: 68px;
  width: 3px;
  transform: rotate(90deg);
  margin: -7px 63px;
}
.drwerBtnLineStyle1 {
  height: 36px;
  width: 3.5px;
  margin: 0px -66px;
  position: relative;
  transform: rotate(90deg);
  top: -12px;
  border-radius: 50px;
}
.drwerDivBtn:hover {
  cursor: pointer;
}

.ant-drawer-header {
  height: 126px;
  position: relative;
  padding: 49px 76px 37px 76px;
  color: rgba(0, 0, 0, 0.65);
  background: #dee3ff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  background-image: url(../../home/assets/notification-circled.svg);
  background-repeat: no-repeat;
  background-position: 103px 9px;
}
.firtContentImg {
  background-color: #f8f8f8;
  height: 91px;
  padding: 10px 8px;
  margin: -24px -24px;
  border-bottom: 1px solid lightgray;
}
#photoTarget {
  object-fit: cover;
  width: 100%;
  max-width: 60px;
  max-height: 60px;
  min-height: 60px;
}
.contentImg {
  margin: 8px 10px;
  background-color: transparent;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  height: 60px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
}
.content {
  width: 155px;
  color: #5061ce;
  font: 13px/17px openSansRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  margin: 0px 0px;
  position: relative;
  top: 6px;
  text-transform: lowercase !important;
}
.content:first-line {
  width: 155px;
  color: #5061ce;
  font: 13px/17px openSansRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  margin: 0px 0px;
  position: relative;
  top: 6px;
  text-transform: capitalize !important;
}

.filaImpar{
  background-color: #f8f8f8;
  border-bottom: 1px solid lightgray;
}
.filaImparcontent{
  background-color: #f8f8f8;
}
.timeduration {
  background-color: #f8f8f8;
  text-align: left;
  font: 10px/17px openSansLight, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  color: #292730;
}
.timedurationtd{
  background-color: #f8f8f8;

}
.cssTable {
  margin: 96px -11px;
  top: 10px;
  left: 10px;
  position: absolute;
  width: 100%;
}
.firtContent {
  width: 155px;
  margin: -79px 57px;
  color: #5061ce;
  font: 13px/17px openSansRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
.dotOnlinediv {
  width: 13px;
  margin: 18px 212px;
}
.dotOnlineimg {
  width: 8px;
}

.secondContentImg {
  background-color: #ffffff;
  height: 91px;
  padding: 10px 8px;
  margin: 46px -24px;
  /* box-shadow: -10px -5px 20px #00000029; */
  border-bottom: 1px solid lightgray;
}
.secondimgoffline {
  margin: 6px 0;
}
.secondContent {
  width: 155px;
  margin: -112px 54px;
  color: #5061ce;
  font: 13px/17px openSansRegular, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
.ant-drawer-mask {
  /* position: absolute;
    top: 304px;
    right: 0;
    left: 888px;
    width: 4%;
    height: 16% !important;
    height: 0; */
  background-color: transparent;
}

@media screen and (min-width: 599px) and (max-width: 5500px) {
  .drwerDivBtn1 {
    display: none !important;
  }
}

.opnnotific {
  width: 53px;
  height: 52px;
  background-color: transparent;
  position: fixed;
  top: 10px;
  right: 43px;
  /* display: flex;
  justify-content: center; */
  border-radius: 50px;
}
@media screen and (min-width: 900px) and (max-width: 1265px) {
  .opnnotific {
    right: 16px;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1400px) {
  .opnnotific {
    right: 3%;
  }
}

@media screen and (min-width: 1401px) and (max-width: 1500px) {
  .opnnotific {
    right: 4%;
  }
}

@media screen and (min-width: 150px) and (max-width: 599px) {
  .drwerDivBtn {
    display: none;
  }
  .drwerDivBtn2 {
    background-image: url(./../../home/assets/menu-24px.svg);
  }
  .drwerDivBtnMove {
    display: none;
  }
  .ant-drawer-mask {
    background-color: rgba(0, 0, 0, 0.45);
  }
  .opnnotific {
    width: 53px;
    height: 52px;
    background-color: transparent;
    position: fixed;
    top: 10px;
    right: 9px;
    border-radius: 50px;
  }
}
