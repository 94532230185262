html,
body {
  height: 100%;
  padding: 0px !important;
}

.containerlogin {
  height: 100vh;
  overflow: hidden;
}

.tituloLogin {
  font: 18px/24px nunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
}
.card-signin {
  width: 100vw !important;
  height: 70% !important;
  border: 0;
  border-radius: 1px 1px 20px 1px;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.card-signin .card-body {
  padding: 2rem;
}
.logo-login {
  width: 40%;
  align-items: center;
}

@font-face {
  font-family: nunitoBold;
  src: url(./../../components/fonts/Nunito/Nunito-Bold.ttf);
}
.btn-outline-login {
  width: 40%;
  height: 100%;
  color: #fff;
  font: 1.5vw nunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  background-color: #4132b4;
  border-color: #4132b4;
}

.btn-outline-login:hover {
  color: #fff;
  background-color: #00aec7;
  border-color: #00aec7;
}

