.tabBar {
  display: flex;
  height: 55px;
  /* border: 1px solid red; */
  /* width: 100%; */
}

.tabItemWrapper {
  height: 100%;
  border-radius: 18px 18px 0px 0px;
  background-color: #f2f2f2;
  display: flex;
  box-shadow: 0px 3px 6px #a7a7a729;
  position: relative;
}
.tabItemWrapper::after {
  position: absolute;
  width: 100%;
  background-color: #f2f2f2;
  content: "";
  height: 30px;
  top: 100%;
}
.ActivetabItemWrapper::after {
  background-color: white !important;
}

.tabItem {
  width: 235px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 18px 18px 0px 0px;
  background-color: inherit;
  position: relative;
  cursor: pointer;
}
.tabItem p {
  display: none;
}
.tabItemActive {
  background-color: white;
}

.tabItem svg > * {
  fill: #a3a3a3;
}
.tabItemActive svg path {
  fill: #4132b4;
}
.tabItemActive .blankChatIcon > * {
  fill: #4132b4 !important;
}
.tabItemActive p {
  display: inherit;
  text-transform: uppercase;
  position: absolute;
  bottom: -80%;
  color: #4132b4;
  z-index: 10;
  font: 20px/70px NunitoBold, Helvetica, Arial, Verdana, Tahoma, sans-serif;
  letter-spacing: 0px;
  opacity: 1;
}

.tabContent {
  border-radius: 18px;
  background-color: white;
  width: 100%;
  height: auto;
  box-shadow: 0px 3px 6px #a7a7a729;
  padding: 2rem min(3vw, 2rem);
  position: relative;
  overflow: hidden;
}

.happyIcon {
  position: absolute;
  width: 285px;
  max-height: 275px;
  min-height: 120px;
  height: 110%;
  top: -41px;
  right: -41px;
}
.chatIcon {
  position: absolute;
  width: 200px;
  max-height: 200px;
  min-height: 120px;
  height: 110%;
  bottom: -75px;
  left: -6px;
}

.tabBarLink {
  align-self: center;
  margin-left: auto;
  margin-right: 18px;
  font-weight: 500;
  color: #00aec7;
  text-decoration: underline;
  font-size: 1.2rem;
}
.tabBarLink:active,
.tabBarLink:visited {
  color: #00aec7;
}

.tabNavBtn {
  display: none;
  width: 60px;
  cursor: pointer;
}
.tabNavBtn svg {
  width: 28px;
  height: 28px;
}

.fixedNavBarOverlay {
  position: fixed;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  z-index: 25;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.closeBtnOverlay {
  background-color: transparent;
  width: 60px;
  height: 60px;
  border: transparent;
  cursor: pointer;
  outline: none;
  border-radius: 50%;
}
.closeBtnOverlay:hover {
  background-color: rgba(65, 50, 180, 0.048);
}
.closeBtnOverlay:focus {
  outline: none;
}

.navBoxOverlay {
  /* border: 1px solid; */
  /* width: auto; */
  max-width: 100%;
  width: 60%;
  margin-top: 12px;
}
.navBoxOverlay div {
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
  height: 35px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
}

.navBoxOverlay div:hover {
  background-color: rgba(65, 50, 180, 0.048);
}

.clrNabBtnItem {
  color: #5061ce !important;
}

@media screen and (max-width: 600px) {
  .tabItemWrapper {
    display: none;
  }

  .tabNavBtn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
